// #############################################################################
// Gebäude: Befüllt die Eingabefelder mit den Standardwerten aus der Karte

(function ($) {
  'use strict';

  var $defaults = {
    'year': '',
    'building_type': '',
    'renovation_type': '',
    'building_form': '',
    'energy_type': '',
    'bgf': '',
    'count': '',
    'usable_area': '',
    'area': '',
  };

  $.fn.extend({
    setDefaultBuildingData: function ($data) {
      $data = $.extend({}, $defaults, $data);

      $.each($data, function (key, value) {
        var $input = $('#' + key);
        var type = $input.attr('type');
        var $summary_input = $('#summary_' + $input.attr('id'));

        if (type === 'number') {
          $input.val(value);
          $summary_input.val(value);
        }
        else if ($input.is('select')) {
          var $options = $('option', $input);

          $options.each(function (index) {
            var $option = $options.eq(index);

            if ($option.text().trim() === value) {
              $option.attr('selected', 'selected');
            }
          });

          $options = $('option', $summary_input);

          $options.each(function (index) {
            var $option = $options.eq(index);

            if ($option.text().trim() === value) {
              $option.attr('selected', 'selected');
            }
          });


        }
        else {
          var $radios = $('[name="' + key + '"]');

          $radios.each(function (index) {
            var $radio = $radios.eq(index);
            var $label = $radio.parents('label');
            var radio_value = $label.text().trim();

            if (value.search(new RegExp(radio_value, 'i')) > -1) {
              $radio.attr('checked', 'checked')
              return false;
            }
          });

          $radios = $('[name="summary-' + key + '"]');

          $radios.each(function (index) {
            var $radio = $radios.eq(index);
            var $label = $radio.parents('label');
            var radio_value = $label.text().trim();

            if (value.search(new RegExp(radio_value, 'i')) > -1) {
              $radio.attr('checked', 'checked')
              return false;
            }
          });
        }

        if (type === 'radio') {
          $summary_input.filter('[value="' + value + '"]').prop('checked', true);
        }

      });

    },

  });
})(jQuery);


// #############################################################################
// Anzeige des Preloader bis die Energieszenario-Berechnung abgeschlossen ist

(function ($) {
  var $modal = $('[data-modal="preloader"]');
  var $form = $('form', $modal);
  var $preloader = $('[data-preloader]');
  var $wizard = $('[data-building-wizard]');
  var $btns = $('[data-next]', $wizard);
  var $save = $('[data-save]');
  var $inputs = $(':input', $wizard);

  $form.on('submit', function () {

    if (this.checkValidity()) {
      $modal.modal('hide');

      $inputs.attr('disabled', 'disabled');
      $btns.addClass('disabled');
      $save.addClass('disabled');

      $btns.attr('aria-disabled', 'true');
      $save.attr('aria-disabled', 'true');

      $btns.attr('tabindex', '-1');
      $save.attr('tabindex', '-1');

      $preloader.removeClass('d-none');
      this.submit();
    }

    return false;
  });
})(jQuery);


// #############################################################################
// Wizzard: Füllt die Gebäudeinformationen-Zusammenfassung automatisch aus

(function($) {
  'use strict';

  var pluginName = 'buildingWizard';

  function Plugin($element) {
    this.$element = $element;
    this.$el = $($element);
    this.$summary = $('[data-summary]', this.$el);
    this.summary_id = this.$summary.attr('data-summary');
    this.$summary_inputs = $(':input', this.$summary);
    this.$inputs = $(':input', this.$el);

    this.init();
  }

  $.extend(Plugin.prototype, {
    init: function() {
      var _this = this;

      _this.updateSummary();
      _this.updateInputs();
    },

    updateSummary: function() {
      var _this = this;

      _this.$inputs.on('blur click input', function() {
        var $input = _this.$inputs.eq(_this.$inputs.index(this));
        var $summary_input = $('#summary_' + $input.attr('id'));
        var type = $input.attr('type');
        var value = $input.val();

        if (type === 'checkbox') {
          if (value === 'on') {
            $summary_input.prop('checked', true);
          }
          else {
            $summary_input.prop('checked', false);
          }
        }
        else if (type === 'radio') {
          $summary_input.filter('[value="' +value + '"]').prop('checked', true);
        }
        else {
          $summary_input.val(value);
        }
      });
    },

    updateInputs: function() {
      var _this = this;

      this.$summary_inputs.on('blur input', function() {
        var $summary_input = _this.$summary_inputs.eq(
          _this.$summary_inputs.index(this)
        );

        var id = $summary_input.attr('id');

        if (id) {
          var $input = $('#' + id.replace(_this.summary_id + '_', ''));

          var type = $summary_input.attr('type');
          var value = $summary_input.val();

          if (type === 'checkbox') {
            if (value === 'on') {
              $input.attr('checked', 'checked');
            }
            else {
              $input.removeAttr('checked');
            }
          }
          else {
            $input.val(value);
          }
        }
      });
    },
  });

  $.fn[pluginName] = function(options) {
    return this.each(function() {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
      }
    });
  };
})(jQuery);

$('[data-building-wizard]').buildingWizard();


(function () {
  var hash = location.hash;
  var $hashes = [
    '#schritt-1',
    '#schritt-2',
    '#schritt-3',
    '#schritt-4',
    '#schritt-5',
    '#zusammenfassung'
  ];

  if ($hashes.indexOf(hash) == -1) {
    history.replaceState(undefined, undefined, '#schritt-1')
  }
})(jQuery);


// #############################################################################
// Building Mobility Redirects

(function ($) {
  'use strict';
  var pluginName = 'buildingMobilityRedirect';

  function Plugin($element) {
    this.$element = $element;
    this.$el = $($element);
    this.$inputs = $('[data-step] [required]', this.$el);
    this.$btns = $('[data-next]', this.$el);
    this.$save = $('[data-save]', this.$el);

    this.init();
  }

  $.extend(Plugin.prototype, {
    init: function () {
    }
  });

  $.fn[pluginName] = function (options) {
    return this.each(function () {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
      }
    });
  };

  $('[data-mobility-wizard]').buildingMobilityRedirect();

})(jQuery);


// #############################################################################
// Wizzard: Formularvalidierung für Gebäudeinformationen

(function ($) {
  'use strict';

  var pluginName = 'buildingWizardValidation';

  function Plugin($element) {
    this.$element = $element;
    this.$el = $($element);
    this.$inputs = $('[data-step] [required]', this.$el);
    this.$btns = $('[data-next]', this.$el);
    this.$save = $('[data-save]', this.$el);
    this.are_buttons_disbled = false;

    this.$summary_sections = $('[data-summary-section]');
    this.$summary_inputs = $('[required]', this.$summary_sections);

    this.init();
  }

  //TODO: Validierung bei Page-Reload
  $.extend(Plugin.prototype, {
    init: function () {
      var _this = this;
      var button = $('[data-next]', $('[data-building-wizard]'));
      button.addClass('disabled');
      button.attr('aria-disabled', 'true');
      button.attr('tabindex', '-1');
      _this.disableButtons();
      _this.checkSummaryValidity();

      var $input = _this.$inputs.eq(_this.$inputs.index(this));
      var is_valid = _this.checkInputValidity($input);


      if ($input == undefined) {
        alert('test');
      }
      if (is_valid) {
        _this.enableButtons();
      }
      else {
        _this.disableButtons();
      }


      _this.$inputs.on('blur input', function () {
        var $input = _this.$inputs.eq(_this.$inputs.index(this));
        var is_valid = _this.checkInputValidity($input);

        /*_this.$inputs.each(function (index) {
            console.log('test');
            _this.checkInputValidity($inputs.eq(index));
        });*/

        if (is_valid) {
          _this.enableButtons();
        }
        else {
          _this.disableButtons();
        }
      });

      _this.$summary_inputs.on('blur input', function () {
        _this.checkSummaryValidity();
      });

      _this.$btns.on('click', function () {
        var $btn = _this.$btns.eq(_this.$btns.index(this));

        _this.checkFieldsetValidity($btn);

        if ($btn.attr('data-next').indexOf('summary') > -1) {
          _this.checkSummaryValidity();
        }

        return _this.are_buttons_disbled ? false : true;
      });

      _this.$el.on('submit', function () {
        return false;
      });
    },

    enableButtons: function () {
      var _this = this;

      _this.$btns.removeClass('disabled');
      _this.$save.removeClass('disabled');

      _this.$btns.removeAttr('aria-disabled');
      _this.$save.removeAttr('aria-disabled');

      _this.$btns.removeAttr('tabindex');
      _this.$save.removeAttr('tabindex');

      _this.are_buttons_disbled = false;
    },

    disableButtons: function () {
      var _this = this;

      this.$btns.addClass('disabled');
      this.$save.addClass('disabled');

      _this.$btns.attr('aria-disabled', 'true');
      _this.$save.attr('aria-disabled', 'true');

      _this.$btns.attr('tabindex', '-1');
      _this.$save.attr('tabindex', '-1');

      _this.are_buttons_disbled = true;
    },

    checkFieldsetValidity: function ($btn) {
      var _this = this;
      var $fieldset = $btn.parents('fieldset');
      var $inputs = $('[required]', $fieldset);

      _this.focusFirstInvalidInput($inputs);

      if (jQuery.isEmptyObject($inputs)) {
        alert('test');
      }

      $inputs.each(function (index) {
        _this.checkInputValidity($inputs.eq(index));
      });
    },

    checkSummaryValidity: function () {
      var _this = this;
      var summary_is_valid = true;

      _this.$summary_sections.each(function (index) {
        var $section = _this.$summary_sections.eq(index);
        var $inputs = $('[required]', $section);
        var $error = $('[data-summary-error]', $section);

        if ($inputs.length) {
          var summary_section_is_valid = true;

          $inputs.each(function (index) {
            var $input = $inputs.eq(index);

            if ($input.length) {
              if (!_this.checkInputValidity($input)) {
                summary_section_is_valid = false;
                summary_is_valid = false;
              }
            }
          });

          if (summary_section_is_valid) {
            $error.addClass('d-none');
            $error.removeClass('d-inline');
          }
          else {
            $error.addClass('d-inline');
            $error.removeClass('d-none');
          }
        }
      });

      if (summary_is_valid) {
        _this.enableButtons();
      }
      else {
        _this.disableButtons();
      }
    },

    checkInputValidity: function ($input) {
      var _this = this;
      var $label = $('[for="' + $input.attr('id') + '"]');
      var $error = $('[data-error]', $label);

      //console.log($input.attr('id') + ' --> ' + $input[0].checkValidity());

      if ($input[0].checkValidity()) {
        $input.removeClass('is-invalid');
        $error.removeClass('d-inline');
        $error.addClass('d-none');
        return true;
      }

      $input.addClass('is-invalid');
      $error.addClass('d-inline');
      $error.removeClass('d-none');

      _this.disableButtons();

      return false;
    },

    focusFirstInvalidInput: function ($inputs) {
      $inputs.each(function (index) {
        var $input = $inputs.eq(index);

        if ($input.is(':invalid')) {
          $input.focus();
          return false;
        }
      });
    }
  });

  $.fn[pluginName] = function (options) {
    return this.each(function () {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
      }
    });
  };
})(jQuery);

$('[data-building-wizard]').buildingWizardValidation();
$('[data-mobility-wizard]').buildingWizardValidation();


// #############################################################################
// Spezialfälle: Gebäudegröße, Energieversorgung

(function ($) {
    'use strict';

    var $form = ('[data-building-wizard]');
    var $area = $('#area', $form);
    var $bgf = $('#bgf', $form);
    var $count = $('#count', $form);
    var $usable_area = $('#usable_area', $form);
    var $property_area = $('#property_area', $form);

    var $energy_type = $('#energy_type', $form);

    // Energieversorgung
    var $renewable_energy_system_type = $('#renewable_energy_system_type', $form);
    var $property_area_thermisch = $('#property_area_thermisch', $form);
    var $property_area_solar = $('#property_area_solar', $form);

    var $summary_renewable_energy_system_type = $('#summary_renewable_energy_system_type', $form);
    var $summary_property_area_thermisch = $('#summary_property_area_thermisch', $form);
    var $summary_property_area_solar = $('#summary_property_area_solar', $form);

    var $label_property_area_thermisch = $('[for="' + $property_area_thermisch.attr('id') + '"]');
    var $error_property_area_thermisch = $('[data-error]', $label_property_area_thermisch);
    var $label_property_area_solar = $('[for="' + $property_area_solar.attr('id') + '"]');
    var $error_property_area_solar = $('[data-error]', $label_property_area_solar);

    var $summary_label_property_area_thermisch = $('[for="' + $summary_property_area_thermisch.attr('id') + '"]');
    var $summary_error_property_area_thermisch = $('[data-error]', $summary_label_property_area_thermisch);
    var $summary_label_property_area_solar = $('[for="' + $summary_property_area_solar.attr('id') + '"]');
    var $summary_error_property_area_solar = $('[data-error]', $summary_label_property_area_solar);

    var $area_summary = $('#summary_area', $form);
    // var $bgf_summary = $('#summary_bgf', $form);
    // var $count_summary = $('summary_count', $form);
    var $usable_area_summary = $('summary_usabable_area', $form);
    var $property_summary = $('summary_property_area', $form);

    //Grundstückspotentiale
    function valid_input() {
      var renewable_energy_systeme_type_value = $renewable_energy_system_type.val()
      var summary_renewable_energy_systme_type_value = $summary_renewable_energy_system_type.val();


      if (summary_renewable_energy_systme_type_value == 0) {
        //summary inputs
        $summary_property_area_thermisch.removeAttr('required');
        $summary_property_area_thermisch.removeClass('is-invalid');
        $summary_property_area_thermisch.addClass('d-none');
        //$summary_property_area_thermisch.prop('disabled', true);
        $summary_property_area_thermisch.val('');

        $summary_error_property_area_thermisch.removeClass('d-inline');
        $summary_error_property_area_thermisch.addClass('d-none');

        $summary_property_area_solar.removeAttr('required');
        $summary_property_area_solar.removeClass('is-invalid');
        $summary_property_area_solar.val('');
        //$summary_property_area_solar.prop('disabled', true);
        $summary_property_area_solar.addClass('d-none');

        $summary_error_property_area_solar.removeClass('d-inline');
        $summary_error_property_area_solar.addClass('d-none');
      }
      else {
        //summary inputs
        //$summary_property_area_thermisch.removeAttr('disabled');
        $summary_property_area_thermisch.removeClass('d-none');
        $summary_property_area_thermisch.attr('required', true);

        //$summary_property_area_solar.removeAttr('disabled');
        $summary_property_area_solar.removeClass('d-none');
        $summary_property_area_solar.attr('required', true);
      }


      if (renewable_energy_systeme_type_value == 0) {
        $property_area_thermisch.removeAttr('required');
        $property_area_thermisch.removeClass('is-invalid');

        $error_property_area_thermisch.removeClass('d-inline');
        $error_property_area_thermisch.addClass('d-none');
        //$property_area_thermisch.prop('disabled', true);
        $property_area_thermisch.addClass('d-none');
        $property_area_thermisch.val('');

        $property_area_solar.removeAttr('required');
        $property_area_solar.removeClass('is-invalid');

        $error_property_area_solar.removeClass('d-inline');
        $error_property_area_solar.addClass('d-none');
        //$property_area_solar.prop('disabled', true);
        $property_area_solar.addClass('d-none');
        $property_area_solar.val('');
      }
      else {
        //$property_area_thermisch.removeAttr('disabled');
        $property_area_thermisch.attr('required', true);
        $property_area_thermisch.removeClass('d-none');

        //$property_area_solar.removeAttr('disabled');
        $property_area_solar.attr('required', true);
        $property_area_solar.removeClass('d-none');
      }


      //Form aktualisieren
      $energy_type.trigger('input');
    }

    function validationUpdate() {
      var area_value = $area.val();
      var bgf_value = $bgf.val();
      var property_area_value = $property_area.val();
      var count_value = $count.val();
      var usable_area_value = $usable_area.val();

      valid_input();

      if ($.isNumeric(property_area_value) && $.isNumeric(area_value)) {
        if (property_area_value < area_value) {
          $('[data-warning="property_bebaute_flaeche"]').removeClass('d-none');
        }
        else {
          $('[data-warning="property_bebaute_flaeche"]').addClass('d-none');
        }
        $property_area.attr('min', area_value);
        $property_summary.attr('min', area_value);
      }

      if ($.isNumeric(bgf_value)) {
        if ($.isNumeric(usable_area_value)) {
          if ((usable_area_value) < (bgf_value * 0.70)) {
            $('[data-warning="nutzflaeche_bgf_kl"]').removeClass('d-none');
          }
          else {
            $('[data-warning="nutzflaeche_bgf_kl"]').addClass('d-none');
          }

          if ((usable_area_value) > (bgf_value * 0.80)) {
            $('[data-warning="nutzflaeche_bgf_gr"]').removeClass('d-none');
          }
          else {
            $('[data-warning="nutzflaeche_bgf_gr"]').addClass('d-none');
          }

          if ((usable_area_value) > (bgf_value * 0.90)) {
            $('[data-warning="nutzflaeche_bgf_gr_error"]').removeClass('d-none');
          }
          else {
            $('[data-warning="nutzflaeche_bgf_gr_error"]').addClass('d-none');
          }
          $usable_area.attr('max', Math.abs(bgf_value * 0.90).toFixed(0));

          if ((usable_area_value) < (bgf_value * 0.20)) {
            $('[data-warning="nutzflaeche_bgf_kl_error"]').removeClass('d-none');
          }
          else {
            $('[data-warning="nutzflaeche_bgf_kl_error"]').addClass('d-none');
          }
          $usable_area.attr('min', Math.abs(bgf_value * 0.20).toFixed(0));
          $usable_area_summary.attr('min', Math.abs(bgf_value * 0.20).toFixed(0));

        }

        if ($.isNumeric(area_value)) {
          if (parseInt(area_value) < parseInt(bgf_value) || ((parseInt(area_value) === parseInt(bgf_value)) && count_value === 1)) {
            $('[data-warning="bgf_bebaute_flaeche"]').removeClass('d-none');
          }
          else {
            $('[data-warning="bgf_bebaute_flaeche"]').addClass('d-none');
          }
          //$bgf.attr('max', area_value);
          //$bgf_summary.attr('max', area_value);
          if ($.isNumeric(count_value)) {
            // BF * Anzahl der Geschosse < 95% der BGF
            if (((count_value) * area_value) < ((bgf_value) * 0.95)) {
              $('[data-warning="geschossanzahl_bgf_kl"]').removeClass('d-none');
              $('.value', $('[data-warning="geschossanzahl_bgf_kl"]')).html(bgf_value + ' m²')
            }
            else {
              $('[data-warning="geschossanzahl_bgf_kl"]').addClass('d-none');
            }

            // BF * Anzahl der Geschosse > 105% der BGF
            if (((count_value) * area_value) > ((bgf_value) * 1.05)) {
              $('[data-warning="geschossanzahl_bgf_gr"]').removeClass('d-none');
              $('.value', $('[data-warning="geschossanzahl_bgf_gr"]')).html(bgf_value + ' m²');
            }
            else {
              $('[data-warning="geschossanzahl_bgf_gr"]').addClass('d-none');
            }


            if (((area_value * count_value) < ((bgf_value * 0.80))) || ((area_value * count_value) > ((bgf_value) * 1.20))) {
              $('[data-warning="bgf_geschossanzahl_bebaute_flaeche"]').removeClass('d-none');
            }
            else {
              $('[data-warning="bgf_geschossanzahl_bebaute_flaeche"]').addClass('d-none');
            }
            $area.attr('max', Math.abs((bgf_value * 1.20) / count_value).toFixed(0));
            $area.attr('min', Math.abs((bgf_value * 0.8) / count_value).toFixed(0));

            $area_summary.attr('max', Math.abs((bgf_value * 1.20) / count_value).toFixed(0));
            $area_summary.attr('min', Math.abs((bgf_value * 0.8) / count_value).toFixed(0));
          }
        }
      }

      // Nutzfläche < BGF und Nutzfläche < Grundstücksfläche
      /*
              if (bgf_value || property_area_value) {
                  var usable_area_max_value = 0;

                  if (bgf_value && property_area_value) {
                      if (bgf_value < property_area_value) {
                          usable_area_max_value = bgf_value;
                      }
                      else if (property_area_value < bgf_value) {
                          usable_area_max_value = property_area_value
                      }
                  }
                  else {
                      if (bgf_value) {
                          usable_area_max_value = bgf_value;
                      }
                      else {
                          usable_area_max_value = property_area_value;
                      }
                  }

                  $usable_area.attr('max', usable_area_max_value);
              }

              // Grundstücksfläche > Bebaute Fläche

              if (area_value) {
                  $property_area.attr('min', area_value);
              }*/
    }

    $renewable_energy_system_type.on('change', validationUpdate);
    $summary_renewable_energy_system_type.on('change', validationUpdate);
    $area.on('blur input', validationUpdate);
    $count.on('blur input', validationUpdate);
    $bgf.on('blur input', validationUpdate);
    $usable_area.on('blur input', validationUpdate);
    $property_area.on('blur input', validationUpdate);

    validationUpdate();
  }

)(jQuery);


// #############################################################################
// Karte: Aktualisiert die verstecken <input> im Speichern-Dialog

(function($) {
  'use strict';

  var pluginName = 'updateSaveBuildingModal';

  function Plugin($element) {
    this.$element = $element;
    this.$el = $($element);
    this.$form = this.$el.parents('form');
    this.$inputs = $('[data-summary] :input', this.$form);
    this.$save_modal = $(this.$el.attr('href'));

    this.init();
  }

  $.extend(Plugin.prototype, {
    init: function() {
      var _this = this;

      _this.$el.on('click', function() {
        _this.updateInputs();
      });
    },

    updateInputs: function() {
      var _this = this;

      this.$inputs.each(function(index) {
        var $input = _this.$inputs.eq(index);
        var name = $input.attr('name');
        console.log(name);

        if (name) {
          name = name.replace(/^(summary-)/, '');

          var $save_input = $('[name$="' + name + '"]', _this.$save_modal);

          if ($input.attr('type') === 'radio') {
            if ($input.is(':checked')) {
              $save_input.val($input.val());
            }
          }
          else {
            $save_input.val($input.val());
          }
        }
      });
    }
  });

  $.fn[pluginName] = function(options) {
    return this.each(function() {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
      }
    });
  };
})(jQuery);

$('[data-save]').updateSaveBuildingModal();
